import { createRef } from 'react';

const useScrollToNextSection = () => {
  const ref = createRef();

  return {
    ref,
    scrollToNextSection: () => {
      const nextEl = ref?.current?.nextElementSibling;

      if (nextEl) {
        const { top } = nextEl.getBoundingClientRect();
        const header = document.querySelector('header.fixed');
        const headerHeight = header ? header.clientHeight : 56;
        window[`scrollTo`]({ top: top-headerHeight, behavior: `smooth` });
      }
    }
  }
}

export default useScrollToNextSection;